<template>
  <trac-loading v-if="loading" />
  <main v-else>
    <main id="table">
      <div class="p-6 rounded-md">
        <div class="flex w-full items-center">
          <div class="relative max-w-sm rounded-md w-full flex items-center">
            <input
              type="text"
              placeholder="Search by account name or amount paid"
              class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
              v-model="search"
            />
            <svg
              class="w-4 h-4 absolute right-0 mr-6"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6.56705"
                cy="6.61686"
                r="5.39909"
                stroke="#253B95"
                stroke-width="1.06786"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.3156 13.3659L10.3799 10.4302"
                stroke="#253B95"
                stroke-width="1.06786"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="flex items-center ml-5">
            <div class="text-xs">Store:</div>
            <div class="ml-4">
              <trac-dropdown
                title="Select Store"
                :menuItems="getStore"
                v-model="store"
              >
              </trac-dropdown>
            </div>
          </div>
          <div class="ml-8 relative w-full">
            <button
              v-click-outside="close"
              @click="active = !active"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              type="button"
              class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex w-32 justify-between capitalize items-center"
            >
              Add filter
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </button>
            <div
              v-show="active"
              @click="close"
              class="absolute white-gradient px-4 py-6 max-w-sm w-full bg-white mt-1 rounded-md"
            >
              <div class="flex w-full justify-between">
                <div class="w-full ml-8">
                  <p class="text-xs font-medium">By Date</p>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      v-model="time"
                      value="today"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Today</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      value="currentMonth"
                      v-model="time"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">This Month</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      name=""
                      value="lastMonth"
                      v-model="time"
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Last Month</p>
                  </div>
                  <div
                    @click.stop="toggle = !toggle"
                    class="mt-2 flex relative items-center"
                  >
                    <svg
                      class="w-3 h-3"
                      :class="{
                        'fill-current text-blue-500': time == 'custom',
                      }"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.975586"
                        y="2.43408"
                        width="12.7281"
                        height="12.2995"
                        rx="1.65333"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.168 1.06738V3.8006"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.51079 1.06738V3.8006"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.975586 6.53404H13.7037"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p
                      @click="open = !open"
                      class="text-xs ml-3 leading-7 text-primaryBlue cursor-pointer"
                    >
                      Select Date
                    </p>
                    <div class="relative">
                      <date-picker
                        ref="datepicker"
                        v-model="custom"
                        :inline="true"
                        v-if="open"
                        @change="handleChange"
                        format="YYYY-MM-DD"
                        class="absolute shadow-md -ml-5 mt-2"
                        range
                      ></date-picker>
                    </div>
                  </div>
                </div>
                <div class="border-l pl-8 w-full">
                  <p class="text-xs font-medium">By Status</p>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      name=""
                      v-model="time"
                      value="Received"
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Received</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      v-model="time"
                      value="Paid Out"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Paid Out</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      v-model="time"
                      value="failed"
                      type="radio"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Failed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="refresh"
            class="flex cursor-pointer items-center font-medium text-primaryBlue text-xs"
          >
            <p>Refresh</p>
            <div>
              <svg
                width="11"
                height="11"
                class="ml-3"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.729989 -0.000976562H4.39666V3.66569H3.66332V1.09389C2.64741 1.51902 1.80998 2.2827 1.29327 3.25522C0.776552 4.22775 0.612409 5.34915 0.828718 6.42897C1.04503 7.50879 1.62845 8.48044 2.4799 9.1789C3.33135 9.87735 4.39832 10.2595 5.49959 10.2606C6.66897 10.2617 7.79798 9.83295 8.67182 9.05586C9.54566 8.27878 10.1034 7.20759 10.2388 6.04608C10.3743 4.88457 10.0781 3.71379 9.40651 2.75647C8.73495 1.79914 7.73492 1.12208 6.59666 0.85409V0.103157C7.85746 0.359702 8.98841 1.05015 9.79276 2.05437C10.5971 3.05859 11.0239 4.31302 10.999 5.59941C10.974 6.88581 10.4988 8.12274 9.65615 9.095C8.81345 10.0673 7.65656 10.7133 6.38676 10.9208C5.11696 11.1282 3.81461 10.8839 2.70632 10.2304C1.59802 9.57683 0.753923 8.55542 0.320879 7.34385C-0.112164 6.13228 -0.106741 4.80724 0.336204 3.59925C0.779149 2.39126 1.63158 1.3768 2.74519 0.732357H0.729989V-0.000976562Z"
                  fill="#253B95"
                />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <div class="w-full mt-8">
            <div>
              <div
                :style="
                  `grid-template-columns:1.7fr 1.2fr 1fr 1fr 1fr 1.5fr 1fr`
                "
                class="grid rounded-tl-md rounded-tr-md border"
              >
                <div
                  v-for="(header, index) in getTableHeader"
                  :key="index"
                  :class="[
                    index == 0 || index == getTableHeader.length - 1
                      ? 'rounded-tl-md rounded-tr-md'
                      : '',
                    { 'grid-space+': index == 0 },
                  ]"
                  class="pl-8 py-5 bg-accentLight uppercase text-xs font-medium w-full"
                >
                  {{ header }}
                </div>
              </div>
            </div>
            <div v-if="filteredBy && filteredBy.length">
              <div
                :style="
                  `grid-template-columns:1.7fr 1.2fr 1fr 1fr 1fr 1.5fr 1fr`
                "
                :class="[
                  i == payments.list_of_payments.length - 1
                    ? 'rounded-bl-md rounded-br-md'
                    : '',
                ]"
                class="grid last:border-b border-l hov cursor-pointer border-r even:bg-veryLight items-center"
                v-for="(payment, i) in filteredBy"
                @click="openModal(payment)"
                :key="i"
              >
                <div
                  class="w-full py-4 pl-8 text-xs uppercase text-accentDark font-normal flex-col"
                >
                  {{ payment.payment_reference }}
                </div>
                <div
                  class="w-full flex flex-col capitalize items-center text-xs text-accentDark font-normal"
                >
                  <div class="pb-1">
                    {{ bankNameType(payment.payment_method) }}
                  </div>
                </div>
                <div
                  class="w-full py-4 pl-8 text-xs text-accentDark font-normal flex-col"
                >
                  {{ payment.amount_paid | formatPrice }}
                </div>
                <div
                  class="w-full py-4 pl-8 text-xs text-accentDark font-normal flex-col"
                >
                  {{
                    moment(payment.date_of_payment_by_customer).format(
                      "Do-MMM-YYYY"
                    )
                  }}
                </div>
                <div
                  class="w-full py-4 pl-8 text-xs capitalize text-accentDark font-normal flex-col"
                >
                  {{ payment.store_name }}
                </div>
                <div
                  class="w-full py-4 pl-8 text-xs uppercase text-accentDark font-normal flex-col"
                >
                  {{
                    payment.payment_log_details.bank_payment_details
                      .account_name
                  }}
                </div>
                <div
                  class="w-full flex items-center justify-between py-4 pl-2 text-xs font-normal"
                >
                  <div>
                    <div
                      class="w-full text-xs font-normal border px-1"
                      :class="status(payment.payment_status)"
                    >
                      {{ payment.payment_status }}
                    </div>
                  </div>
                  <svg
                    class="w-5 h-5 mr-6"
                    viewBox="0 0 4 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="1.825"
                      cy="7.5999"
                      r="0.825"
                      stroke="#253B95"
                      stroke-width="0.9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <circle
                      cx="1.825"
                      cy="1.825"
                      r="0.825"
                      stroke="#253B95"
                      stroke-width="0.9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <circle
                      cx="1.825"
                      cy="13.3748"
                      r="0.825"
                      stroke="#253B95"
                      stroke-width="0.9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="border py-3 pl-5 text-xs border-t-0">
                <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      There is currently no data available.
                    </div>
                  </trac-center-data>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <trac-modal
      top="pt-10"
      size="max-w-3xl"
      class="w-full"
      v-if="modal"
      @close="modal = false"
    >
      <div class="pt-10 px-16">
        <h1 class="text-xs font-medium">Details</h1>
        <div class="mt-5 pb-6 border-b flex justify-between">
          <div>
            <h1 class="text-xs text-accentDark">Amount</h1>
            <h2
              class="text-base tracking-wide font-medium py-1 text-accentDark"
            >
              {{ modalObject.amount_paid | formatPrice }}
            </h2>
            <div
              class="mt-1 inline-block text-xs font-medium px-2 py-1 rounded-l-full rounded-r-full"
              :class="[
                {
                  'bg-green-300  text-green-500':
                    modalObject.payment_status == 'paid',
                },
                {
                  'bg-yellow-200 text-yellow-500':
                    modalObject.payment_status == 'pending',
                },
                {
                  'bg-red-200 text-red-500':
                    modalObject.payment_status == 'failed',
                },
              ]"
            >
              {{ modalObject.payment_status }}
            </div>
          </div>
          <h2 class="text-primaryBlue font-medium text-xs"></h2>
        </div>
        <div class="mt-5">
          <h1 class="text-xs text-primaryGray">Customer</h1>
          <h2 class="text-primaryBlue text-xs mt-1 underline">
            {{ modalObject.customer_name }}
          </h2>
          <div class="mt-5 grid grid-cols-2 items-center">
            <div>
              <h1 class="text-xs text-primaryGray">Date</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{
                  moment(modalObject.date_of_payment_by_customer).format(
                    "Do-MMM-YYYY"
                  )
                }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Reference</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{ modalObject.payment_reference }}
              </h2>
            </div>
          </div>
        </div>
        <div class="mt-6 -mx-5 table-shadow rounded-md px-4 py-5">
          <div
            v-if="
              modalObject.payment_method == 'bank_transfer' ||
                modalObject.payment_method == 'fixed_account'
            "
          >
            <h1 class="text-sm font-medium uppercase">PAYMENT Details</h1>
            <div class="grid grid-cols-2 mt-4 row-gap-6">
              <div>
                <h1 class="text-xs text-primaryGray">Payment Method</h1>
                <h2 class="text-accentDark text-xs mt-1">
                  {{ bankNameType(modalObject.payment_method) }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Source Bank</h1>
                <h2 class="text-accentDark text-xs mt-1">
                  {{
                    modalObject.payment_log_details.bank_payment_details
                      .bank_name
                  }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Account Name</h1>
                <h2 class="text-accentDark text-xs mt-1">
                  {{
                    modalObject.payment_log_details.bank_payment_details
                      .account_name
                  }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Account Number</h1>
                <h2 class="text-accentDark text-xs mt-1">
                  {{
                    modalObject.payment_log_details.bank_payment_details
                      .account_number
                  }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Payment receipt</h1>
                <h2
                  @click="openReceipt = !openReceipt"
                  class="text-primaryBlue cursor-pointer underline text-xs mt-1"
                >
                  {{ !openReceipt ? "View receipt" : "Hide Receipt" }}
                </h2>
                <span v-if="openReceipt" class="text-xs"
                  ><a
                    class="text-primaryBlue"
                    target="_blank"
                    :href="
                      modalObject.payment_log_details.payment_details
                        .receipt_url
                    "
                  >
                    {{
                      modalObject.payment_log_details.payment_details
                        .payment_receipt
                    }}: Visit Link</a
                  ></span
                >
              </div>
            </div>
          </div>
          <div v-else>
            <h1 class="text-sm font-medium uppercase">PAYMENT Details</h1>
            <div class="grid grid-cols-2 mt-4 row-gap-6">
              <div>
                <h1 class="text-xs text-primaryGray">Payment Method</h1>
                <h2 class="text-accentDark text-xs mt-1 uppercase">
                  {{ modalObject.payment_method }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Card Type</h1>
                <h2 class="text-accentDark box text-xs mt-1">
                  {{
                    modalObject.payment_log_details.payment_details.card_type
                  }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Card number</h1>
                <h2 class="text-accentDark text-xs mt-1">
                  {{
                    modalObject.payment_log_details.payment_details.card_number
                  }}
                </h2>
              </div>
              <div>
                <h1 class="text-xs text-primaryGray">Payment receipt</h1>
                <h2
                  @click="openReceipt = !openReceipt"
                  class="text-primaryBlue cursor-pointer underline text-xs mt-1"
                >
                  {{ !openReceipt ? "View receipt" : "Hide Receipt" }}
                </h2>
                <span v-if="openReceipt" class="text-xs"
                  ><a
                    class="text-primaryBlue"
                    target="_blank"
                    :href="
                      modalObject.payment_log_details.payment_details
                        .receipt_url
                    "
                  >
                    {{
                      modalObject.payment_log_details.payment_details
                        .payment_receipt
                    }}: Visit Link</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 -mx-5 table-shadow rounded-md px-4 py-5">
          <div class="flex items-center justify-between">
            <h1 class="text-sm font-medium uppercase">Sales Details</h1>
            <h1
              class="tiny font-medium uppercase text-primaryBlue items-center flex"
            >
              View SALES RECORD
              <svg
                class="ml-2"
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.5L6.4 6.1L1 0.700001"
                  stroke="#003283"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </h1>
          </div>
          <div class="grid grid-cols-2 mt-4 row-gap-6">
            <div>
              <h1 class="text-xs text-primaryGray">Sales ID</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{ modalObject.payment_log_details.sales_details.sales_id }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Sold By</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{ modalObject.payment_log_details.sales_details.sold_by }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Store Name</h1>
              <h2 class="text-accentDark text-xs mt-1">
                {{ modalObject.payment_log_details.sales_details.store_name }}
              </h2>
            </div>
            <div>
              <h1 class="text-xs text-primaryGray">Customer Name</h1>
              <h2 class="text-xs mt-1">
                {{
                  modalObject.payment_log_details.customer_details.customer_name
                }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="downloadCsv" @close="downloadCsv = false">
      <div class="p-5">
        <h1 class="text-sm mt-5">Download CSV</h1>
        <div class="mt-10 w-full pr-12">
          <div class="relative w-full">
            <div
              @click="csvDropdown = !csvDropdown"
              v-click-outside="drop"
              class="border cursor-pointer h-12 rounded-md px-3 flex items-center capitalize text-xs"
            >
              <div class="flex items-center h-full" v-if="csvStore.length">
                <div v-for="(store, i) in csvStore" :key="i" class="mr-5">
                  {{ store.name }}
                </div>
              </div>
              <div class="h-full flex items-center" v-else>Select Store</div>
              <svg
                width="16"
                height="9"
                class="absolute right-0 mr-6"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 8L15 1"
                  stroke="#333333"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div
              v-if="csvDropdown"
              @click.stop
              class="w-64 absolute z-50 mt-2 py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div
                class="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                v-for="(store, index) in getAllCsv"
                :key="index"
              >
                <div
                  class="block px-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  <div
                    class="flex py-2 items-center cursor-pointer capitalize text-xs"
                  >
                    <input
                      :value="store"
                      v-model="csvStore"
                      type="checkbox"
                      class="mr-3"
                    />
                    {{ store.name }}
                  </div>
                </div>
              </div>
            </div>
            <div id="csv" class="mt-8 w-full">
              <date-picker
                class="w-full py-3 text-xs"
                v-model="csvDate"
                format="YYYY-MM-DD"
                range
                placeholder="Select Date Range"
              ></date-picker>
            </div>
            <div class="mt-6">
              <h2 class="text-xs">Select Fields</h2>
              <div class="mt-6 grid grid-cols-3 col-gap-4 row-gap-6">
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="fields"
                    value="account_name"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs">Account Name</p>
                </div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="fields"
                    value="transaction_id"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs">Reference ID</p>
                </div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="fields"
                    value="created_at"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs">Date of payment</p>
                </div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="fields"
                    value="payment_method"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs">Payment Channel</p>
                </div>
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    v-model="fields"
                    value="amount"
                    class="w-4 h-4"
                  />
                  <p class="pl-3 text-xs">Amount Paid</p>
                </div>
              </div>
            </div>
            <div class="pb-6 flex justify-center items-center mt-16">
              <trac-button @click.native="jsonPayment">
                <span class="px-6">Download</span>
              </trac-button>
            </div>
          </div>
          <!-- <trac-dropdown
            class="w-full"
            v-model="csvStore"
            :menuItems="storeData"
          ></trac-dropdown> -->
        </div>
      </div>
    </trac-modal>
    <trac-confirmation
      :active="csvActive"
      @close="csvActive = false"
      :type="csvType"
    >
      {{ message }}
    </trac-confirmation>
  </main>
</template>

<script>
import axios from "axios";
import Fuse from "fuse.js";
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { Parser } from "json2csv";
import { GET_LOCAL_DB_DATA } from '../../browser-db-config/localStorage';

export default {
  directives: {
    ClickOutside,
  },

  components: { DatePicker },
  data() {
    return {
      downloadCsv: false,
      loading: false,
      toggle: false,
      modal: false,
      active: false,
      csvDropdown: false,
      open: false,
      items: [],
      time: "today",
      current: `Today's`,
      custom: null,
      moment,
      payments: [],
      filtered: null,
      original: null,
      search: "",
      getTableHeader: [
        "Payment Reference",
        "Payment Method",
        "Amount",
        "Payment Date",
        "Store",
        "Account Name",
        "Status",
      ],
      modalObject: null,
      openReceipt: false,
      storeData: [],
      store: "all",
      csvStore: [],
      csvDate: "",
      fields: [],
      csvActive: false,
      csvType: false,
      message: "",
    };
  },
  watch: {
    time: {
      immediate: true,
      handler(x, y) {
        let payload = {};
        switch (x) {
          case "today":
            this.current = `Today's`;
            payload.startDate = this.moment().format("YYYY-MM-DD");
            payload.endDate = this.moment().format("YYYY-MM-DD");
            this.singlePaymentRequest(payload);
            break;
          case "currentMonth":
            this.current = `This Month's`;
            payload.startDate = this.moment()
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.endDate = this.moment().format("YYYY-MM-DD");
            this.singlePaymentRequest(payload);
            break;
          case "lastMonth":
            this.current = `Last Month's`;
            payload.startDate = this.moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            payload.endDate = this.moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
            this.singlePaymentRequest(payload);
            break;
          case "custom":
            if (this.custom) {
              this.current = "Total";
              payload.startDate = this.moment(this.custom[0]).format(
                "YYYY-MM-DD"
              );
              payload.endDate = this.moment(this.custom[1]).format(
                "YYYY-MM-DD"
              );
              this.singlePaymentRequest(payload);
            }
            break;
          default:
            break;
        }
      },
    },
    custom: {
      handler(x, y) {
        this.time = "custom";
        let payload = {};
        payload.startDate = this.moment(this.custom[0]).format("YYYY-MM-DD");
        payload.endDate = this.moment(this.custom[1]).format("YYYY-MM-DD");
        this.singlePaymentRequest(payload);
        this.$refs.datepicker.closePopup();
        this.active = false;
      },
    },
  },

  methods: {
    bankNameType(x) {
      if (x == "bank_transfer") {
        return "Bank Transfer (Dynamic)";
      } else if (x == "fixed_account") {
        return "Bank Transfer (Fixed)";
      } else {
        return x;
      }
    },
    refresh() {
      let payload = {};
      this.current = `Today's`;
      payload.startDate = this.moment().format("YYYY-MM-DD");
      payload.endDate = this.moment().format("YYYY-MM-DD");
      this.singlePaymentRequest(payload);
    },
    async jsonPayment() {
      let k = [];
      this.csvStore.length
        ? (k = this.csvStore.map(({ _id }) => {
            return _id;
          }))
        : (k = []);

      let payload = {};
      payload.startDate = this.moment(this.csvDate[0]).format("YYYY-MM-DD");
      payload.endDate = this.moment(this.csvDate[1]).format("YYYY-MM-DD");
      payload.storeIds = {
        list_of_store_ids: k,
      };
      let res = await this.$store.dispatch("GET_JSON_PAYMENT", payload);
      if (res.data.items) {
        let mappedData = res.data.items.map(
          ({
            account_name,
            transaction_id,
            created_at,
            payment_method,
            amount,
          }) => {
            return {
              account_name,
              transaction_id,
              created_at,
              payment_method,
              amount,
            };
          }
        );
        let selected = [];
        let allHeaders = [
          "account_name",
          "transaction_id",
          "created_at",
          "payment_method",
          "amount",
        ];
        this.fields.length ? (selected = this.fields) : (selected = allHeaders);
        const opts = { selected };
        try {
          const parser = new Parser(opts);
          const csv = parser.parse(mappedData);
          this.generateBlob([csv], (dataUrl) => {
            const element = document.createElement("a");
            element.href = dataUrl;
            element.download = `payments-${moment()}.csv`;
            element.click();
            this.fields = [];
            this.csvDate = "";
            this.downloadCsv = false;
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        this.csvActive = true;
        this.message = "There is no available data";
        this.csvType = false;
      }
    },
    generateBlob(csv, callback) {
      const file = new Blob(["\ufeff", csv], {
        type: "text/csv;charset=utf-8",
      });
      callback(window.URL.createObjectURL(file));
    },

    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      let res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.storeData = res.data || {};
      }
    },

    openModal(x) {
      this.modal = true;
      this.modalObject = x;
    },
    handleChange(x, y) {
      if (x || y) {
        this.open = false;
      }
    },
    handleChange(x, y) {
      if (x || y) {
        this.open = false;
      }
    },
    async singlePaymentRequest(payload) {
      this.loading = true;
      let res = await this.$store.dispatch("FETCH_ALL_PAYMENTS", payload);
      if (res.status) {
        this.payments = res.data.items;
        this.payments = {
          ...this.payments,
          list_of_payments: this.payments.list_of_payments || [],
        };
        this.loading = false;
      }
    },
    close() {
      this.active = false;
      this.toggle = false;
    },
    drop() {
      this.csvDropdown = false;
    },
    popModal(x) {
      this.modal = true;
    },
    repeat(str, num) {
      var holder = [];
      for (var i = 0; i < num; i++) {
        holder.push(str);
      }
      return holder.join(" ");
    },

    openDate() {
      this.$refs.calender.$el.click();
    },

    status(x) {
      if (x === "Paid Out") {
        return "text-green-500 cap font-medium border-green-500";
      } else if (x === "Received") {
        return "text-yellow-500 cap font-medium border-yellow-500";
      } else {
        return "text-red-500 cap font-medium border-red-500";
      }
    },
    titleCase(string) {
      let sentence = string.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    },
    getInitials(string) {
      let names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
  },
  async created() {
    await this.fetchAllStores();
  },
  computed: {
    getStore() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });
      let v = [{ name: "all", _id: "all" }, ...u];
      return v;
    },
    getAllCsv() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });

      return u;
    },
    getUserStatus() {
      let usersStatus = GET_LOCAL_DB_DATA("traction-app-user-data");
      if (usersStatus.user.role === "admin") {
        return true;
      } else {
        return false;
      }
    },
    gridColumns() {
      let k = "1fr";
      let b = this.repeat(k, this.items.length - 1);
      return `1.4fr ${b}`;
    },

    filteredBy() {
      let allData = this.payments.list_of_payments || [];
      let allStoreNames = [];
      const options = {
        keys: [
          "payment_log_details.bank_payment_details.account_name",
          "amount_paid",
        ],
      };
      let allFilteredStoreName;
      if (this.store == "all") {
        this.getStore.map((x) => {
          allStoreNames.push(x.name.toLowerCase());
        });
        allFilteredStoreName = allData.filter((data) => {
          let k = allStoreNames.includes(data.store_name.toLowerCase());
          return k;
        });
      } else {
        allData.length
          ? (allFilteredStoreName = allData.filter(
              (data) =>
                data.store_name.toLowerCase() == this.store.toLowerCase()
            ))
          : (allFilteredStoreName = []);
      }
      let searchedData = [];
      const fuse = new Fuse(allFilteredStoreName, options);
      this.search == ""
        ? (searchedData = allFilteredStoreName)
        : fuse.search(this.search).map((x) => searchedData.push(x.item));
      if (
        this.time == "Paid Out" ||
        this.time == "Received" ||
        this.time == "failed"
      ) {
        return searchedData.filter((status) => {
          return status.payment_status === this.time;
        });
      } else {
        return searchedData;
      }
    },

    initials() {
      let k = [...this.payments.list_of_payments];

      let b = k.map((u) => {
        return this.getInitials(u.name);
      });
      return b;
    },
  },
};
</script>

<style>
.dd {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.hov:hover {
  background: rgba(190, 182, 182, 0.173);
}
#csv .mx-input {
  height: 44px !important;
  font-size: 12px;
}
#csv .mx-input::placeholder {
  color: #333 !important;
}
</style>
