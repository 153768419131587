<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <!-- Receipt Print -->
    <div class="print-slip text-center" v-if="filteredCustomers">
      <div class="mb-6">
        <div
          class="flex justify-center"
          v-if="businessDetails.logo && businessDetails.logo !== ''"
        >
          <img :src="businessDetails.logo" alt="" width="200" />
        </div>
        <!-- <h3 class="mb-1 mt-2 font-bold">{{ businessDetails.name }}</h3>
        <p class="tiny">{{ businessDetails.email }}</p> -->
        <p class="tiny">
          {{
            `${
              businessDetails.address.city === ""
                ? ""
                : businessDetails.address.city + ","
            } ${
              businessDetails.address.state === ""
                ? ""
                : businessDetails.address.state + ","
            } ${
              businessDetails.address.country === ""
                ? ""
                : businessDetails.address.country
            }`
          }}
        </p>
        <p class="tiny">{{ `Sold by: ${businessUserDetails.name}` }}</p>
      </div>
      <hr class="my-2 h-2 border-black" />
      <!-- Date and Time -->
      <div class="">
        <p class="tiny font-bold">Receipt</p>
        <p class="flex justify-center">
          <span class="tiny">{{ new Date().toLocaleString() }}</span>
        </p>
      </div>
      <!-- Sale items -->
      <ul>
        <li
          class="flex justify-between my-1"
          v-for="(item, index) in selectedProducts"
          :key="index"
        >
          <p class="tiny">
            {{ item.title }} x {{ item.quantity || item.count }}
          </p>
          <span class="tiny">{{
            (item.quantity * item.price) | formatPrice
          }}</span>
        </li>
      </ul>
      <hr class="h-2 border-black" />
      <!--  -->
      <div
        class="flex justify-between"
        v-if="
          sale.totalDiscount.discount_type === 'percentage' &&
          sale.totalDiscount.percentage > 0
        "
      >
        <p class="tiny">Discount:</p>
        <span class="tiny">- {{ sale.totalDiscount.percentage + "%" }}</span>
      </div>
      <!--  -->
      <div
        class="flex justify-between"
        v-if="
          sale.totalDiscount.discount_type === 'amount' &&
          sale.totalDiscount.amount > 0
        "
      >
        <p class="tiny">Discount:</p>
        <span class="tiny"
          >- {{ sale.totalDiscount.amount | formatPrice }}</span
        >
      </div>
      <div class="flex justify-between">
        <p class="tiny">Total:</p>
        <span class="tiny">{{ total | formatPrice }}</span>
      </div>
      <!-- Sale Description -->
      <div class="" v-if="sale.sale_description !== ''">
        <p class="tiny font-bold">Note included in the sale:</p>
        <p class="tiny">{{ sale.sale_description }}</p>
      </div>
      <!-- Payment Options -->
      <div class="tiny">
        <p v-if="!splitPaymentCtrl" class="flex justify-between">
          <span>Method of payment:</span>
          <span v-if="selectedOption">{{ selectedOption.name }}</span>
        </p>
        <div class="flex justify-between" v-else>
          <span>Method of payment:</span>
          <div class="grid">
            <span v-for="(payment, index) in splitPayments" :key="index">{{
              payment.name
            }}</span>
          </div>
        </div>
      </div>
      <!-- Loyalty -->
      <div class="" v-if="sale.customer._id">
        <p class="tiny capitalize">
          {{ sale.customer.first_name + " " + sale.customer.last_name }}
        </p>
      </div>
      <!-- Customer name -->
      <div
        class=""
        v-if="
          !sale.loyaltyData.active &&
          sale.customer._id &&
          businesCustomerLoaylty
        "
      >
        <hr class="my-1 h-2 border-black" />
        <p class="tiny flex justify-between">
          <span>Loyalty Earned: </span>
          <span>
            {{ accumulatedLoyaltyPoints | formatPrice }}
          </span>
        </p>
      </div>
      <div class="" v-if="businessStoreCreditStatus && sale.customer._id">
        <hr class="my-1 h-2 border-black" />
        <!-- Store Credit -->
        <p class="tiny flex justify-between">
          <span>Store Credit:</span>
          <span v-if="customerStoreCreditData">{{
            customerStoreCreditData.Data.Credit | formatPrice
          }}</span>
        </p>
      </div>
      <div class="" v-if="customerLoyaltyData">
        <hr class="my-1 h-2 border-black" />
        <!-- Loyalty Balance -->
        <p class="tiny flex justify-between">
          <span>Current Loyalty Balance:</span>
          <span>{{ initial_customer_loyalty_points | formatPrice }}</span>
        </p>
      </div>
      <div class="">
        <hr class="my-1 h-2 border-black" />
        <!-- Total Balance -->
        <p
          class="tiny flex justify-between"
          v-if="customerStoreCreditData && customerLoyaltyData"
        >
          <span>Total Balance:</span>
          <span>{{
            (customerStoreCreditData.Data.Credit +
              initial_customer_loyalty_points)
              | formatPrice
          }}</span>
        </p>
        <!--  -->
        <p
          class="tiny flex justify-between"
          v-else-if="customerStoreCreditData"
        >
          <span>Total Balance:</span>
          <span>{{ customerStoreCreditData.Data.Credit | formatPrice }}</span>
        </p>
        <!--  -->
        <p class="tiny flex justify-between" v-else-if="customerLoyaltyData">
          <span>Total Balance:</span>
          <span>{{ initial_customer_loyalty_points | formatPrice }}</span>
        </p>
      </div>
      <p class="tiny">{{ businessDetails.name }}</p>
      <p class="tiny">
        {{
          `${
            businessDetails.address.city === ""
              ? ""
              : businessDetails.address.city + ","
          } ${
            businessDetails.address.state === ""
              ? ""
              : businessDetails.address.state + ","
          } ${
            businessDetails.address.country === ""
              ? ""
              : businessDetails.address.country
          }`
        }}
      </p>
      <!-- <div class="mt-6">
        <p class="tiny font-bold">Prowered by Traction.</p>
      </div> -->
    </div>

    <main class="md:ml-12 mb-10 non-print-slip" v-if="filteredCustomers">
      <!-- <button @click="printReceipt">print</button> -->
      <trac-modal @close="ticketModalCtrl = false" v-if="ticketModalCtrl">
        <NewTicket
          :saveTicketName="saveTicketName"
          @create-ticket="saveTicket"
        ></NewTicket>
      </trac-modal>
      <trac-modal
        size="max-w-6xl"
        @close="paymentLog = false"
        v-if="paymentLog"
      >
        <PaymentLog />
      </trac-modal>

      <trac-back-button>POS Dashboard</trac-back-button>
      <div
        class="flex flex-row mt-12 pr-4 md:pr-0 mb-8 items-end justify-between"
      >
        <div class="flex-col">
          <h2>POS</h2>
          <h3 class="font-bold mt-4 text-lg">Your Sale</h3>
        </div>
        <div v-if="!nextMenu" class="flex-col">
          <div class="flex flex-row gap-5">
            <trac-button
              class="uppercase"
              @button-clicked="ticketModalCtrl = true"
              >Save Ticket</trac-button
            >
            <!-- <trac-button class="uppercase">Quick Sale</trac-button> -->
          </div>
        </div>
      </div>

      <div class="grid md:flex flex-row gap-5">
        <div class="flex-col md:w-7/12 pr-4 md:pr-0">
          <div class="border bg-white rounded-lg overflow-auto" style="">
            <table class="w-full table-auto overflow-auto">
              <thead class="bg-blue-100">
                <tr class="h-20">
                  <th class="text-left pl-8 text-xs font-semibold uppercase">
                    No.
                  </th>
                  <th class="text-left text-xs font-semibold uppercase">
                    Item
                  </th>
                  <th class="text-left text-xs font-semibold uppercase">
                    Unit Price
                  </th>
                  <th class="text-left text-xs font-semibold uppercase">
                    Quantity
                  </th>
                  <th class="text-left text-xs font-semibold uppercase">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                  v-for="(item, index) in selectedProducts"
                  class="h-20"
                  :key="index"
                >
                  <td class="text-xs pl-8">
                    {{ index + 1 }}
                  </td>
                  <td class="text-xs font-bold capitalize">
                    {{ item.title }}
                  </td>
                  <td class="text-xs font-medium" v-if="item.quickSale">
                    {{ "N/A" }}
                  </td>
                  <td class="text-xs font-medium" v-else>
                    <div class="flex flex-col justify-center">
                      <span
                        class="line-through"
                        v-if="item.price !== item.initial_price"
                        >{{ item.initial_price | formatPrice }}</span
                      >
                      <span>{{ item.price | formatPrice }}</span>
                    </div>
                  </td>
                  <td class="text-xs font-medium capitalize">
                    x {{ item.quantity || item.count }}
                  </td>
                  <td
                    class="text-xs font-medium capitalize"
                    v-if="item.quickSale"
                  >
                    {{ item.quickSaleTotal | formatPrice }}
                  </td>
                  <td class="text-xs font-medium capitalize" v-else>
                    {{ (item.quantity * item.price) | formatPrice }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex flex-row gap-8 mt-16 justify-end mr-6">
            <div class="flex-column">
              <div class="font-semibold text-xs">Sub Total:</div>
              <div class="font-semibold text-xs">Discount:</div>
              <div
                class="font-semibold text-xs"
                v-if="businesCustomerLoaylty && sale.loyaltyData.active"
              >
                Loyalty:
              </div>
              <div
                class="font-semibold text-xs"
                v-if="sale.storeCreditData.active"
              >
                Store Credit:
              </div>
              <div class="font-semibold text-xs">Total:</div>
            </div>
            <div class="flex-column">
              <div class="font-semibold text-xs">
                {{ totalPlaceholder | formatPrice }}
              </div>
              <div
                class="font-semibold text-xs"
                v-if="sale.totalDiscount.discount_type === 'percentage'"
              >
                {{ sale.totalDiscount.percentage + "%" }}
              </div>
              <div class="font-semibold text-xs" v-else>
                {{ sale.totalDiscount.amount | formatPrice }}
              </div>
              <div
                class="font-semibold text-xs"
                v-if="businesCustomerLoaylty && sale.loyaltyData.active"
              >
                {{ sale.loyaltyData.redeemValue | formatPrice }}
              </div>
              <div
                class="font-semibold text-xs"
                v-if="sale.storeCreditData.active"
              >
                {{ sale.storeCreditData.redeemValue | formatPrice }}
              </div>
              <div class="font-semibold text-xs">{{ total | formatPrice }}</div>
            </div>
          </div>
        </div>
        <div v-if="!nextMenu" class="flex-col md:w-5/12 pr-4 md:pr-0">
          <div class="bg-white rounded-lg big-shadow px-8 py-8 h-full">
            <span class="text-sm font-medium text-primaryGray">Collect:</span>
            <div>
              <div class="flex justify-between items-center mb-4">
                <span class="text-2xl block font-bold">{{
                  (splitPaymentCtrl ? splitTotalSum : total) | formatPrice
                }}</span>
                <div class="" v-if="updateSaleFlag"></div>
                <div class="" v-else>
                  <div class="" v-if="amountPaid === 0 || amountPaid === ''">
                    <button
                      @click="splitPaymentCtrl = false"
                      class="text-xs outline-none"
                      v-if="splitPaymentCtrl"
                    >
                      Total Payment
                    </button>
                    <!-- Comment Split Payment -->

                    <!-- <button
                      @click="splitPaymentCtrl = true"
                      class="outline-none text-xs"
                      v-else
                    >
                      Split Payment
                    </button> -->
                  </div>
                </div>
              </div>
              <div class="my-2" v-if="splitPayments.length > 0">
                <p class="text-xs font-bold text-gray-600">Splited Payments</p>
                <ul>
                  <li
                    class="text-xs pl-2"
                    v-for="(split, index) in splitPayments"
                    :key="index"
                  >
                    - {{ split.amount | formatPrice }}
                  </li>
                </ul>
              </div>
              <trac-dropdown-exteneded
                class="absolute mb-2"
                :neededProperty="'customerFullName'"
                :disabled="sale.loyaltyData.active"
                :selector="saleCustomer || 'Select Customer'"
                :options="sortCustomersNames"
                :shouldFilter="true"
                :addProp="true"
                @optionSelected="selectCustomer($event)"
                @filter-list="filterCustomersList"
                @add-prop="addCustomerOnTheFly"
              ></trac-dropdown-exteneded>

              <trac-input
                v-model.number="amountPaid"
                v-if="splitPaymentCtrl"
                class="mt-4"
                placeholder="Amount"
              ></trac-input>
            </div>

            <div class="mt-8">
              <span class="text-xs font-medium text-primaryGray"
                >Choose a payment method</span
              >
              <div
                class="flex-col cursor-pointer"
                @click="selectOption(option)"
                v-for="(option, index) in options"
                :key="index"
              >
                <!-- <div> -->
                <div
                  v-if="!isPaymentSelected"
                  @click="isPaymentSelected = true"
                  class="border border-primaryBlue rounded-lg px-2 py-2 mt-5"
                >
                  <div class="flex flex-row items-center gap-5">
                    <img src="../../assets/svg/pay_method.svg" />
                    <span class="uppercase text-xs text-primaryGray">{{
                      option.name
                    }}</span>
                  </div>
                </div>
              </div>
              <!--                        <trac-dropdown-exteneded v-if="isPaymentSelected" selector="Payment Method"-->
              <!--                                                 @optionSelected="selectOption"-->

              <!--                                                 :options="options"></trac-dropdown-exteneded>-->
              <trac-payment-dropdown
                class="mt-4"
                v-if="isPaymentSelected"
                @optionSelected="
                  isSavedTicketSale = false;
                  selectPaymentOption($event);
                "
                :selectedOption="selectedOption"
                :options="
                  this.sale.sale_ticket_info
                    ? checkTicketPaymentMethod
                    : options
                "
              ></trac-payment-dropdown>
              <div class="mt-10">
                <div v-if="selectedOption && selectedOption.id === 1">
                  <trac-input
                    v-model.number="amountPaid"
                    placeholder="Collect Cash"
                  ></trac-input>
                  <trac-button
                    :disabled="
                      splitPaymentCtrl
                        ? amountPaid === 0 ||
                          amountPaid === '' ||
                          amountPaid > splitTotalSum
                        : amountPaid < total
                    "
                    @button-clicked="
                      updateSaleFlag ? updateSale() : createSale()
                    "
                    class="uppercase my-6 float-right"
                    >Tender</trac-button
                  >
                </div>
                <div v-else-if="selectedOption && selectedOption.id === 2">
                  <trac-input
                    disabled
                    v-model.number="amountPaid"
                    placeholder="Collect Cash"
                  ></trac-input>
                  <trac-button
                    :disabled="
                      splitPaymentCtrl
                        ? amountPaid > splitTotalSum
                        : amountPaid < total
                    "
                    @button-clicked="
                      updateSaleFlag ? updateSale() : createSale()
                    "
                    class="uppercase mt-6 mb-6 float-right"
                    >Make payment</trac-button
                  >
                </div>
                <div
                  v-else-if="
                    companyBankAccount &&
                    selectedOption &&
                    selectedOption.id === 3
                  "
                >
                  <span class="text-primaryGray text-center text-xs"
                    >Please ask the customer to transfer
                    <b class="text-black">{{ total | formatPrice(false) }}</b>
                    to this account number.</span
                  >
                  <img
                    class="mx-auto mt-3 mb-8 h-16"
                    :src="companyBankAccount.bank_logo"
                  />
                  <p class="mb-2 text-primaryGray">
                    {{ companyBankAccount.settlement_bank }}
                  </p>
                  <p class="mb-2 text-xs">
                    {{ companyBankAccount.business_name }}
                  </p>

                  <div
                    class="
                      flex flex-row
                      items-center
                      justify-between
                      border
                      px-2
                      py-2
                      rounded
                    "
                  >
                    <span class="font-medium text-primaryGray text-xs"
                      >Account Number:
                      <input
                        class="text-black border-none outline-none"
                        :value="companyBankAccount.account_number"
                    /></span>
                    <div class="bg-veryLight px-2 py-2 rounded">
                      <img
                        @click="copyCode"
                        class="cursor-pointer"
                        src="../../assets/svg/copy.svg"
                      />
                    </div>
                  </div>
                  <div class="mt-8 text-center">
                    <a :href="`mailto:${sale.customer.email || ''}`">
                      <span
                        class="
                          font-medium
                          text-xs text-primaryBlue
                          cursor-pointer
                        "
                        >Send to customer</span
                      >
                      <img
                        class="mx-auto cursor-pointer"
                        src="../../assets/svg/email1.svg"
                      />
                      <span class="text-xs font-medium cursor-pointer"
                        >Email</span
                      >
                    </a>
                  </div>
                  <div class="mt-8 text-center">
                    <trac-input
                      class="mb-5"
                      v-model.number="amountPaid"
                      placeholder="Amount Received"
                    ></trac-input>
                    <!-- <span
                      v-if="!refreshClicked"
                      @click="refreshClicked = true"
                      class="uppercase text-xs font-bold text-primaryBlue cursor-pointer"
                      >click to refresh</span
                    > -->
                    <trac-button
                      :disabled="
                        splitPaymentCtrl
                          ? amountPaid === 0 ||
                            amountPaid === '' ||
                            amountPaid > splitTotalSum
                          : amountPaid < total
                      "
                      @button-clicked="
                        updateSaleFlag ? updateSale() : createSale()
                      "
                      class="uppercase float-right mb-8"
                      >Next</trac-button
                    >
                  </div>
                </div>
                <div
                  v-else-if="
                    saleDetail && selectedOption && selectedOption.id === 4
                  "
                >
                  <span class="text-primaryGray text-center text-xs"
                    >Please ask the customer to transfer
                    <b class="text-black">{{ total | formatPrice(false) }}</b>
                    to this account number.</span
                  >
                  <img
                    class="mx-auto mt-3 mb-8 h-16"
                    :src="saleDetail.bank_details.bank_logo"
                  />
                  <p class="mb-2 text-primaryGray">
                    {{ saleDetail.bank_details.bank_name }}
                  </p>
                  <p class="mb-2 text-xs">
                    {{ saleDetail.bank_details.account_name }}
                  </p>
                  <div
                    class="
                      flex flex-row
                      items-center
                      justify-between
                      border
                      px-2
                      py-2
                      rounded
                    "
                  >
                    <span class="font-medium text-primaryGray text-xs"
                      >Account Number:
                      <input
                        class="text-black border-none outline-none"
                        :value="saleDetail.bank_details.account_number"
                    /></span>
                    <div class="bg-veryLight px-2 py-2 rounded">
                      <img
                        @click="copyCode"
                        class="cursor-pointer"
                        src="../../assets/svg/copy.svg"
                      />
                    </div>
                  </div>
                  <div class="mt-8 text-center">
                    <a :href="`mailto:${sale.customer.email || ''}`">
                      <span
                        class="
                          font-medium
                          text-xs text-primaryBlue
                          cursor-pointer
                        "
                        >Send to customer</span
                      >
                      <img
                        class="mx-auto cursor-pointer"
                        src="../../assets/svg/email1.svg"
                      />
                      <span class="text-xs font-medium cursor-pointer"
                        >Email</span
                      >
                    </a>
                  </div>

                  <div class="mt-8 text-center">
                    <trac-input
                      class="mb-5"
                      v-model.number="amountPaid"
                      placeholder="Amount Received"
                    ></trac-input>
                    <trac-button
                      @buttom-clicked="goToNextMenu"
                      v-if="amountPaid >= total"
                      class="uppercase float-right mb-8"
                      >Next</trac-button
                    >
                    <span
                      v-else
                      @click="refreshPayment"
                      class="
                        uppercase
                        text-xs
                        font-bold
                        text-primaryBlue
                        cursor-pointer
                      "
                      >click to refresh</span
                    >
                  </div>
                </div>
                <div
                  v-else-if="
                    saleDetail && selectedOption && selectedOption.id === 5
                  "
                >
                  <span class="text-primaryGray text-center text-xs"
                    >Please ask the customer to transfer
                    <b class="text-black">{{ total | formatPrice(false) }}</b>
                    to this account number.</span
                  >
                  <img
                    class="mx-auto mt-3 mb-8 w-7/12"
                    src="https://www.about-payments.com/logo/300/225/2791"
                  />
                  <div class="text-center">
                    <a
                      target="_blank"
                      class="underline"
                      :href="saleDetail.payment_link"
                      >Pay here</a
                    >
                  </div>
                  <div class="mt-8 text-center">
                    <a :href="`mailto:${sale.customer.email || ''}`">
                      <span
                        class="
                          font-medium
                          text-xs text-primaryBlue
                          cursor-pointer
                        "
                        >Send to customer</span
                      >
                      <img
                        class="mx-auto cursor-pointer"
                        src="../../assets/svg/email1.svg"
                      />
                      <span class="text-xs font-medium cursor-pointer"
                        >Email</span
                      >
                    </a>
                  </div>

                  <div class="mt-8 text-center">
                    <trac-input
                      class="mb-5"
                      v-model.number="amountPaid"
                      placeholder="Amount Received"
                    ></trac-input>
                    <trac-button
                      @buttom-clicked="goToNextMenu"
                      v-if="amountPaid >= total"
                      class="uppercase float-right mb-8"
                      >Next</trac-button
                    >
                    <span
                      v-else
                      @click="refreshPayment"
                      class="
                        uppercase
                        text-xs
                        font-bold
                        text-primaryBlue
                        cursor-pointer
                      "
                      >click to refresh</span
                    >
                  </div>
                </div>
                <div v-else-if="selectedOption && selectedOption.id === 6">
                  <trac-input
                    disabled
                    v-model.number="amountPaid"
                    placeholder="Collect Cash"
                  ></trac-input>
                  <div
                    @click="paymentLog = true"
                    class="
                      mt-5
                      text-xs text-primaryBlue
                      font-medium
                      uppercase
                      flex
                      items-center
                      cursor-pointer
                    "
                  >
                    Check payment Log
                    <div>
                      <svg
                        width="7"
                        height="13"
                        class="ml-3"
                        viewBox="0 0 7 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 11.5L6.4 6.1L1 0.700001"
                          stroke="#003283"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <trac-button
                    :disabled="amountPaid < total"
                    @button-clicked="
                      updateSaleFlag ? updateSale() : createSale()
                    "
                    class="uppercase mt-6 mb-6 float-right"
                    >Confirm payment</trac-button
                  >
                </div>
                <div v-else-if="selectedOption && selectedOption.id > 6">
                  <trac-input
                    disabled
                    v-model.number="amountPaid"
                    placeholder="Collect Cash"
                  ></trac-input>
                  <!-- <div
                    @click="paymentLog = true"
                    class="mt-5 text-xs text-primaryBlue font-medium uppercase flex items-center cursor-pointer"
                  >
                    Check payment Log
                    <div>
                      <svg
                        width="7"
                        height="13"
                        class="ml-3"
                        viewBox="0 0 7 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 11.5L6.4 6.1L1 0.700001"
                          stroke="#003283"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div> -->

                  <trac-button
                    :disabled="amountPaid < total"
                    @button-clicked="
                      updateSaleFlag ? updateSale() : createSale()
                    "
                    class="uppercase mt-6 mb-6 float-right"
                    >Confirm payment</trac-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="nextMenu" class="flex-col md:w-5/12 pr-4 md:pr-0">
          <div class="bg-white rounded-lg big-shadow px-8 py-8 h-full">
            <div>
              <img class="m-auto my-4" src="../../assets/svg/tick.svg" />
              <div class="my-6">
                <p class="text-xs my-2 text-gray-600">
                  Amount Paid:
                  <span class="text-black">{{
                    saleDetail.amount_tendered | formatPrice
                  }}</span>
                </p>
                <p
                  class="text-xs my-2 text-gray-600"
                  v-if="
                    !sale.loyaltyData.active &&
                    sale.customer._id &&
                    businesCustomerLoaylty
                  "
                >
                  Accumulated Loyalty Points:
                  <span class="text-black">{{
                    accumulatedLoyaltyPoints | formatPrice
                  }}</span>
                </p>
                <p class="text-xs my-2 text-gray-600">
                  Balance:
                  <span class="text-black">{{
                    calcBalance | formatPrice
                  }}</span>
                </p>
              </div>
              <trac-input
                :disabled="!sale._id"
                v-model="reciepientEmail"
                class="mt-4"
                placeholder="Email to..."
              ></trac-input>
              <trac-button
                :disabled="reciepientEmail.length <= 6 || !sale._id"
                class="uppercase mt-5 float-right"
                @button-clicked="sendReceipt"
                >Send</trac-button
              >
            </div>
            <div class="flex flex-row justify-between gap-5 mt-24">
              <trac-button @button-clicked="printReceipt" class="uppercase"
                >Print</trac-button
              >
              <trac-button class="uppercase" @button-clicked="doNothing"
                >Make New Sale</trac-button
              >
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";
import NewTicket from "./NewTicket";
import PaymentLog from "./PaymentLog";
import Fuse from "fuse.js";
import {
  ADD_DATA_TO_DB,
  CONNECT_TO_DB,
  GET_DB_DATA,
  GET_OBJECT_STORE,
  SAVE_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";
import { eventBus } from "./../../main";

export default {
  name: "YourSale",
  // ================================================================================================= Components
  components: {
    NewTicket,
    PaymentLog,
  },
  // ================================================================================================= Data
  data() {
    return {
      isLoading: false,
      splitPaymentCtrl: false,
      splitPayments: [],
      businessStoreCreditStatus: false,
      businesCustomerLoaylty: false,
      paymentLog: false,
      defaultPaymentMethods: [
        "cash",
        "pos",
        "own_bank_account",
        "bank_transfer",
        "online_payment",
        "fixed_account",
      ],
      options: [
        { id: 1, name: "Cash", icon: "pay_method.svg", method: "cash" },
        { id: 2, name: "Pos", icon: "pay_method.svg", method: "pos" },
        {
          id: 3,
          name: "Bank Transfer",
          icon: "pay_method.svg",
          method: "own_bank_account",
        },
        {
          id: 4,
          name: "Bank Transfer (Dynamic Account)",
          icon: "pay_method.svg",
          method: "bank_transfer",
        },
        {
          id: 5,
          name: "online Payment",
          icon: "pay_method.svg",
          method: "online_payment",
        },

        {
          id: 6,
          name: "Bank Transfer (Fixed)",
          icon: "pay_method.svg",
          method: "fixed_account",
        },
      ],
      bankOptions: [],
      amountPaid: "",
      saveTicketName: "",
      reciepientEmail: "",
      customerLoyaltyData: null,
      customerStoreCreditData: null,
      sale: null,
      initial_customer_loyalty_points: 0,
      companyBankAccount: null,
      saleDetail: null,
      selectedOption: null,
      updateSaleFlag: null,
      isPaymentSelected: false,
      isSavedTicketSale: false,
      refreshClicked: false,
      ticketModalCtrl: false,
      bankSelected: false,
      nextMenu: false,
      businessDetails: false,
      nextMenu: false,
      filteredCustomers: null,
    };
  },
  // ================================================================================================= Created
  async created() {
    this.isLoading = true;
    //
    this.makeCustomPaymentRequest();
    this.businesCustomerLoaylty = GET_USER_BUSINESS_DATA().loyalty_active;
    this.businessDetails = GET_USER_BUSINESS_DATA();
    this.businessUserDetails = GET_USER_DATA();
    this.allowSetLoyalty = this.businessDetails.loyalty_active;

    this.sale = GET_LOCAL_DB_DATA("sale-details");
    await this.fetchAllCustomers();

    if (this.sale._id) {
      this.updateSaleFlag = true;
    }

    // Check ticket details
    if (this.sale.sale_ticket_info) {
      this.saveTicketName = this.sale.sale_ticket_info.ticket_name;
      if (this.sale.sale_ticket_info.ticket_status === 2) {
        this.isSavedTicketSale = true;
        this.selectedOption = this.options.find(
          (opt) => opt.method === this.sale.sale_ticket_info.payment_method
        );
        this.selectOption(this.selectedOption);
        this.selectPaymentOption(this.selectedOption);
        this.isPaymentSelected = true;
      }
    }

    //
    this.checkBusinesStoreCreditActive();

    // Loyalty payment for product
    this.settlePaymentWithLoyalty();

    this.isLoading = false;
  },
  // ================================================================================================= beforeEnter
  beforeRouteEnter(to, from, next) {
    if (!GET_LOCAL_DB_DATA("sale-details")) {
      next({ name: "PosDashboard" });
    } else {
      next();
    }
  },
  // ================================================================================================= destroyed
  destroyed() {
    if (this.updateSaleFlag) {
      DELETE_LOCAL_DB_DATA("sale-details");
      this.$store.dispatch("REFRESH_ALL_PRODUCTS");
    }

    if (
      this.$route.name !== "PosDashboard" &&
      this.$route.name !== "PosPayment"
    ) {
      DELETE_LOCAL_DB_DATA("sale-details");
      this.$store.dispatch("REFRESH_ALL_PRODUCTS");
    }
  },
  // ================================================================================================= Computed
  computed: {
    calcBalance() {
      return this.splitPaymentCtrl
        ? this.amountPaid +
            this.splitPayments.reduce(
              (total, split) => total + split.amount,
              0
            ) -
            this.total
        : this.amountPaid - this.total;
    },
    splitTotalSum() {
      return (
        this.total -
        this.splitPayments.reduce((total, split) => total + split.amount, 0)
      );
    },
    checkTicketPaymentMethod() {
      return this.options.filter(
        (opt) =>
          opt.method === "own_bank_account" ||
          opt.method === "bank_transfer" ||
          opt.method === "online_payment"
      );
    },
    accumulatedLoyaltyPoints() {
      return this.selectedProducts.reduce((total, item) => {
        total +=
          (item.quantity || item.count) *
          item.price *
          item.loyalty_points *
          0.01;

        return total;
      }, 0);
    },
    // Holds the total value for evaluations
    totalPlaceholder() {
      return this.selectedProducts.reduce((total, product) => {
        if (product.quickSale) {
          return total + product.quickSaleTotal;
        } else {
          return total + product.quantity * product.price;
        }
      }, 0);
    },
    total() {
      return (
        this.selectedProducts.reduce((total, product) => {
          if (product.quickSale) {
            return total + product.quickSaleTotal;
          } else {
            return total + product.quantity * product.price;
          }
        }, 0) -
        this.setLoyaltyDiscount -
        this.setStoreCreditDiscount -
        (this.sale.totalDiscount.discount_type === "percentage"
          ? this.totalPlaceholder * this.sale.totalDiscount.amount * 0.01
          : this.sale.totalDiscount.amount)
      );
    },
    setLoyaltyDiscount() {
      return this.sale.loyaltyData.redeemValue === ""
        ? 0
        : this.sale.loyaltyData.redeemValue;
    },
    setStoreCreditDiscount() {
      return this.sale.storeCreditData.redeemValue === ""
        ? 0
        : this.sale.storeCreditData.redeemValue;
    },
    selectedProducts() {
      const selections = [];

      this.sale.products.forEach((product) => {
        if (product.isSelected) {
          selections.push(product);
        } else if (product.variation) {
          product.varieties.forEach((va) => {
            if (va.isSelected) {
              selections.push(va);
            }
          });
        }
      });

      return selections;
    },
    saleCustomer() {
      return this.sale.customer.customerFullName || "";
    },
    sortCustomersNames() {
      return this.filteredCustomers.sort((a, b) =>
        a.customerFullName.toLowerCase() > b.customerFullName.toLowerCase()
          ? 1
          : -1
      );
    },
  },
  // ================================================================================================= Methods
  methods: {
    async settlePaymentWithLoyalty() {
      if (this.total === 0) {
        await this.createSale();
      }
    },
    async printReceipt() {
      if (this.businessStoreCreditStatus && this.sale.customer._id) {
        await this.fetchStoreCreaditData();
      }
      print();
    },
    checkBusinesStoreCreditActive() {
      this.businessStoreCreditStatus = GET_LOCAL_DB_DATA(
        "traction-app-current-user-business-data"
      ).store_credit_active;
    },
    async fetchStoreCreaditData() {
      // const offlineStoreCreditDB = await CONNECT_TO_DB(
      //   "offline_db_all_store_credits",
      //   1,
      //   "all_store_credits"
      // );
      let offlineStoreCredit = await GET_OBJECT_STORE(
        "offline_db_all_store_credits",
        "all_store_credits"
      );

      if (offlineStoreCredit) {
        if (!this.customerStoreCreditData) {
          this.customerStoreCreditData = {
            Data: {},
          };
        }

        this.customerStoreCreditData.Data = offlineStoreCredit.Data.find(
          (storeCreditData) =>
            storeCreditData.CustomerID === this.sale.customer._id
        ) || { Credit: 0 };
      }
    },
    async makeCustomPaymentRequest() {
      let res = await this.$store.dispatch("CUSTOMER_PAYMENT_REQUEST");
      if (res.data.items !== null && res.status) {
        let formattedData = res.data.items.map((item, index) => {
          let dataObject = {
            id: index + 7,
            name: item.name,
            icon: "pay_method.svg",
            method: item.name,
          };
          return dataObject;
        });
        this.options = [...this.options, ...formattedData];
      }
    },
    copyCode(e) {
      const copyText =
        e.target.parentElement.previousElementSibling.children[0];
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },
    selectCustomer(option) {
      this.sale.customer = option;
    },
    resetAllAccount() {
      this.companyBankAccount = null;
      this.saleDetail = null;
    },
    async sendReceipt() {
      this.isLoading = true;
      // Setup emails
      let emails = this.reciepientEmail.split(",");

      emails = emails.map((email) => email.trim());

      for (let index = 0; index < emails.length; index++) {
        await this.$store.dispatch("SEND_RECEIPT", {
          sale: this.saleDetail,
          email: emails[index],
        });
      }
      const res = this.$store.getters["GET_SALE_RES"];
      // Send Email

      if (res.status) {
        eventBus.$emit("trac-alert", { message: res.message });
        this.$store.commit("SET_UP_SALE_RES", null);
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async selectPaymentOption(option) {
      if (this.isSavedTicketSale) {
        return;
      }
      this.isLoading = true;
      this.resetAllAccount();
      if (option.id > 6) {
        this.amountPaid = this.total;
      }
      if (
        option.method === "pos" ||
        option.method === "fixed_account" ||
        option.id > 6
      ) {
        if (!this.splitPaymentCtrl) {
          this.amountPaid = this.total;
        }
      } else if (
        this.splitPaymentCtrl &&
        (option.method === "cash" || option.method === "own_bank_account")
      ) {
        // this.amountPaid = ;
      } else {
        this.amountPaid = "";
      }

      this.selectedOption = option;
      if (option.method === "own_bank_account") {
        await this.fetchBankDetails();
      } else if (option.method === "ussd") {
        await this.createSale();
        await this.fetchAllBankAccount();
      } else if (
        option.method !== "pos" &&
        option.method !== "cash" &&
        option.method !== "fixed_account" &&
        option.id <= 6
      ) {
        if (this.updateSaleFlag) {
          await this.updateSale();
        } else {
          await this.createSale();
        }
      }
      this.isLoading = false;
    },
    selectOption(option) {
      if (option.method === "pos" || option.id > 6) {
        if (!this.splitPaymentCtrl) {
          this.amountPaid = this.total;
        }
      } else if (
        (this.splitPaymentCtrl && option.method === "cash") ||
        option.method === "own_bank_account"
      ) {
        // this.amountPaid = ;
      } else {
        this.amountPaid = "";
      }
      this.selectedOption = option;
    },
    selectBank(bank) {
      this.bankSelected = true;
      return bank;
    },
    goToNextMenu() {
      this.reciepientEmail = this.sale.customer
        ? this.sale.customer.email || ""
        : "";
      this.nextMenu = true;
    },
    doNothing() {
      DELETE_LOCAL_DB_DATA("sale-details");
      this.$router.back();
    },
    filterCustomersList(input) {
      if (input.length > 0 && input.length < 5) {
        return;
      }

      let searchedData = [];
      const options = {
        keys: ["customerFullName", "phone"],
      };
      const fuse = new Fuse(this.customers, options);
      if (input == "") {
        this.filteredCustomers = this.customers.slice(0, 30);
      } else {
        this.filteredCustomers = this.customers.filter((customer) => {
          customer;
          return customer.phone
            ? customer.customerFullName
                .toLowerCase()
                .includes(input.toLowerCase()) || customer.phone.includes(input)
            : customer.customerFullName
                .toLowerCase()
                .includes(input.toLowerCase());
        });
      }
    },
    async fetchAllBankAccount() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_BANKS");

      const res = this.$store.getters["GET_ALL_LIVE_BANK_ACCOUNTS"];

      if (res.status) {
        this.bankOptions = res.data;
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async fetchBankDetails() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_BANK_ACCOUNTS");

      const res = this.$store.getters["GET_ALL_BANK_ACCOUNTS"];

      if (res.status) {
        if (res.data) {
          this.companyBankAccount = res.data[0];
        } else {
          eventBus.$emit("trac-alert", {
            message:
              res.message || res.error || "Error: No account registered yet",
          });
        }
      } else {
        eventBus.$emit("trac-alert", { message: res.message || res.error });
      }
      this.isLoading = false;
    },
    async saveTicket(ticketName) {
      this.isLoading = true;
      if (this.sale.sale_ticket_info) {
        const payload = {
          saleID: this.sale.sale_ticket_info._id,
          payment_method: this.selectedOption
            ? this.selectedOption.method
            : "cash",
          body: {
            ticket_status: this.selectedOption
              ? this.selectedOption.method === "bank_transfer" ||
                this.selectedOption.method === "own_bank_account" ||
                this.selectedOption.method === "online_payment"
                ? 1
                : 1 // 1
              : 1, // 1
            ticket_name: ticketName,
            // amount_tendered: this.amountPaid === "" ? 0 : this.amountPaid,
            customer_name: this.saleCustomer || "",
            customer_email: this.sale.customer ? this.sale.customer.email : "",
          },
        };

        await this.$store.dispatch("UPDATE_SALE", payload);
      } else {
        const payload = {
          ...this.setupPayload(),
          ticket_status: this.selectedOption
            ? this.selectedOption.method === "bank_transfer" ||
              this.selectedOption.method === "own_bank_account" ||
              this.selectedOption.method === "online_payment"
              ? 1
              : 1 // 1
            : 1, // 1
          ticket_name: ticketName,
        };
        await this.$store.dispatch("MAKE_SALE", payload);
      }
      const res = this.$store.getters["GET_SALE_RES"];

      this.$store.commit("SET_UP_SALE_RES", null);

      if (res.status) {
        this.updateSaleFlag = res.data;
        eventBus.$emit("trac-alert", { message: res.message });
        // this.goToNextMenu();
        this.ticketModalCtrl = false;
        this.doNothing();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    addCustomerOnTheFly() {
      const payload = {
        origin: {
          name: "POS",
        },
        sale: this.sale,
      };

      SAVE_LOCAL_DB_DATA("create-customer-on-the-fly", payload);
      this.$router.push({ name: "AddCustomer" });
    },
    async refreshPayment() {
      this.isLoading = true;
      const httpReq = {
        url: `/v1/sales/${
          this.saleDetail._id
        }/businesses/${GET_USER_BUSINESS_ID()}`,
        method: "GET",
        authRequest: true,
      };
      const httpRes = await HTTP_REQUEST(httpReq);
      if (httpRes.status) {
        this.saleDetail = httpRes.data;
        this.amountPaid = this.saleDetail.amount_tendered || 0;
      } else {
        // alert(httpRes.message);
        eventBus.$emit("trac-alert", { message: httpRes.message });
      }
      this.isLoading = false;
    },
    async updateSale() {
      this.isLoading = true;
      const payload = {
        saleID: this.sale._id || this.updateSaleFlag._id,
        payment_method: this.selectedOption
          ? this.selectedOption.method
          : "cash",
        body: {
          sale_id: this.sale._id || this.updateSaleFlag._id,
          amount_tendered: this.amountPaid,
          customer_name: this.saleCustomer || "",
          customer_email: this.sale.customer ? this.sale.customer.email : "",
        },
      };

      // Send Sale

      await this.$store.dispatch("UPDATE_SALE", payload);
      const res = this.$store.getters["GET_SALE_RES"];

      this.$store.commit("SET_UP_SALE_RES", null);

      if (res.status) {
        this.saleDetail = res.data;

        // await this.$store.dispatch("REFRESH_SALES_HISTORY");

        if (
          res.data.payment_method === "cash" ||
          res.data.payment_method === "pos" ||
          res.data.payment_method === "own_bank_account" ||
          res.data.payment_method === "fixed_account"
        ) {
          if (this.splitPaymentCtrl) {
            // Update the splits
            this.splitPayments.push({
              amount: this.amountPaid,
              name: this.selectedOption.name,
            });
            // Reset selected option
            this.selectedOption = null;
            this.amountPaid = 0;
            this.isPaymentSelected = false;

            if (this.splitTotalSum === 0) {
              this.goToNextMenu();
            }
          } else {
            this.goToNextMenu();
          }
        } else {
          // alert(res.message);
          eventBus.$emit("trac-alert", { message: res.message });
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    deductCustomerLoyaltyPoints() {
      // this.isLoading = true;
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");

      return fetch(
        `https://tractionloyalty-stage.herokuapp.com/redeem_points/${this.sale.customer._id} `,
        {
          method: "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify({
            point: this.sale.loyaltyData.redeemValue,
            business: GET_USER_BUSINESS_ID(),
          }),
        }
      );
    },
    deductStoreCreditPoints() {
      // this.isLoading = true;
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");

      return fetch(
        `https://tractionloyalty-stage.herokuapp.com/redeem_store_credit/${GET_USER_BUSINESS_ID()}/${
          this.sale.customer._id
        } `,
        {
          method: "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify({
            credit: this.sale.storeCreditData.redeemValue,
            // business: GET_USER_BUSINESS_ID(),
          }),
        }
      );
    },
    makeid(l) {
      let text = "";
      let charList = "abcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < l; i++) {
        text += charList.charAt(Math.floor(Math.random() * charList.length));
      }

      if (offlineIDs.includes(text)) {
        text = "";
        return makeid(l);
      } else {
        offlineIDs.push(text);
        return text;
      }
    },
    getSpecificStore(prod) {
      return (
        prod.store_stock.find((ss) => ss.store_id === this.sale.store._id) || {}
      );
    },
    async createSale() {
      this.isLoading = true;
      const payload = this.setupPayload();
      let updateFlag = false;

      // Send Sale

      if (
        payload.payment_method === "cash" ||
        payload.payment_method === "pos" ||
        payload.payment_method === "fixed_account" ||
        payload.payment_method === "own_bank_account" ||
        !this.defaultPaymentMethods.includes(payload.payment_method)
      ) {
        // await this.$store.dispatch("MAKE_OFFLINE_SALE", {
        //   ...payload,
        //   offline_sale_id: this.makeid(24),
        // });

        await this.$store.dispatch("MAKE_SALE", payload);

        // Update stock
        // const offlineProductsDB = await CONNECT_TO_DB(
        //   "offline_db_products",
        //   1,
        //   "products"
        // );
        // let offlineProducts = await GET_OBJECT_STORE('offline_db_products', "products");

        // if (offlineProducts.status) {
        //   payload.items.forEach((item) => {
        //     offlineProducts.items = offlineProducts.items.map((product) => {
        //       if (item._id === product._id) {
        //         this.getSpecificStore(product).stock -= item.quantity;
        //       }
        //       return product;
        //     });
        //   });

        //   await SAVE_OBJECT_STORE('offline_db_products', "products", offlineProducts);
        // }
      } else {
        await this.$store.dispatch("MAKE_SALE", payload);
        const res = this.$store.getters["GET_SALE_RES"];

        if (res.status) {
          // await this.$store.dispatch("REFRESH_SALES_HISTORY");
        }

        // // Update sale locally if sale is successful
        // const offlineSalesDB = await CONNECT_TO_DB(
        //   "offline_db_sales_history",
        //   1,
        //   "sales_history"
        // );
        // let offlineSales = await GET_DB_DATA(offlineSalesDB, "sales_history");

        // if (res.status) {
        //   if (!offlineSales.data) {
        //     offlineSales.data = {
        //       salesHistory: [],
        //     };
        //   }

        //   offlineSales.data.salesHistory.unshift(res.data);

        //   await ADD_DATA_TO_DB(offlineSalesDB, "sales_history", offlineSales);
        // }
      }

      const res = this.$store.getters["GET_SALE_RES"];

      this.$store.commit("SET_UP_SALE_RES", null);

      if (res.status) {
        this.updateSaleFlag = true;
        this.saleDetail = res.data;
        this.updateSaleFlag = res.data;

        // Check if loyalty was activated
        if (this.sale.loyaltyData.active) {
          // Deduct points from offline loyalty of customer in indexedDB
          // const offlineLoyaltyPointsDB = await CONNECT_TO_DB(
          //   "offline_db_all_loyalty_points",
          //   1,
          //   "all_loyalty_points"
          // );
          let offlineLoyaltyPoints = await GET_OBJECT_STORE(
            "offline_db_all_loyalty_points",
            "all_loyalty_points"
          );

          this.customerLoyaltyData = { Data: {} };

          if (offlineLoyaltyPoints) {
            (offlineLoyaltyPoints.Data || []).forEach((lp, i) => {
              if (lp.CustomerID === this.sale.customer._id) {
                lp.Points -= this.sale.loyaltyData.redeemValue;
                this.initial_customer_loyalty_points = lp.Points;
              }
            });
          }

          await SAVE_OBJECT_STORE(
            "offline_db_all_loyalty_points",
            "all_loyalty_points",
            offlineLoyaltyPoints
          );

          // fetch from db for not synced data
          // const notSyncedOfflineLoyaltyPointsDB = await CONNECT_TO_DB(
          //   "not_synced_offline_loyalty_points",
          //   1,
          //   "offline_loyalty_points"
          // );

          let notSyncedofflineLoyaltyPoints =
            (await GET_OBJECT_STORE(
              "not_synced_offline_loyalty_points",
              "offline_loyalty_points"
            )) || [];

          this.selectedProducts.forEach((product) => {
            notSyncedofflineLoyaltyPoints.push({
              customer: this.sale.customer._id,
              redeem_point: this.sale.loyaltyData.redeemValue,
              txnID: this.makeid(24),
              op: "redeem",
              offline: true,
            });
          });

          await SAVE_OBJECT_STORE(
            "not_synced_offline_loyalty_points",
            "offline_loyalty_points",
            notSyncedofflineLoyaltyPoints
          );
        }

        // Check if store credit was activated
        if (this.sale.storeCreditData.active) {
          // Deduct store credit from offline store credit of customer in indexedDB
          // const offlineStoreCreditDB = await CONNECT_TO_DB(
          //   "offline_db_all_store_credits",
          //   1,
          //   "all_store_credits"
          // );

          let offlineStoreCredit = await GET_OBJECT_STORE(
            "offline_db_all_store_credits",
            "all_store_credits"
          );

          if (offlineStoreCredit) {
            (offlineStoreCredit.Data || []).forEach((sc, i) => {
              if (sc.CustomerID === this.sale.customer._id) {
                sc.Credit -= this.sale.storeCreditData.redeemValue;
              }
            });
          }

          await SAVE_OBJECT_STORE(
            "offline_db_all_store_credits",
            "all_store_credits",
            offlineStoreCredit
          );

          // fetch from db for not synced data
          // const notSyncedOfflineStoreCreditsDB = await CONNECT_TO_DB(
          //   "not_synced_offline_store_credits",
          //   1,
          //   "offline_store_credits"
          // );

          let notSyncedofflineStoreCredits =
            (await GET_OBJECT_STORE(
              "not_synced_offline_store_credits",
              "offline_store_credits"
            )) || [];

          this.selectedProducts.forEach((product) => {
            notSyncedofflineStoreCredits.push({
              customer: this.sale.customer._id,
              credit: this.sale.storeCreditData.redeemValue,
              op: "redeem",
              offline: true,
            });
          });

          await SAVE_OBJECT_STORE(
            "not_synced_offline_store_credits",
            "offline_store_credits",
            notSyncedofflineStoreCredits
          );

          // Register Sync event in SW
          navigator.serviceWorker.ready.then((swReg) => {
            swReg.sync.register("not_synced_offline_store_credits");
          });
        }

        if (
          res.data.payment_method === "cash" ||
          res.data.payment_method === "pos" ||
          res.data.payment_method === "own_bank_account" ||
          res.data.payment_method === "fixed_account" ||
          !this.defaultPaymentMethods.includes(payload.payment_method)
          // res.data.payment_method !== "online_payment" ||
          // res.data.payment_method !== "bank_transfer" ||
          // res.data.payment_method
        ) {
          if (this.splitPaymentCtrl) {
            // Update the splits
            this.splitPayments.push({
              amount: this.amountPaid,
              name: this.selectedOption.name,
            });
            // Reset selected option
            this.selectedOption = null;
            this.amountPaid = 0;
            this.isPaymentSelected = false;
          } else {
            this.goToNextMenu();
          }
        } else {
          // alert(res.message);
          eventBus.$emit("trac-alert", { message: res.message });
        }

        if (
          this.allowSetLoyalty &&
          this.sale.customer._id &&
          !this.sale.loyaltyData.active
        ) {
          // fetch from db
          // const notSyncedOfflineLoyaltyPointsDB = await CONNECT_TO_DB(
          //   "not_synced_offline_loyalty_points",
          //   1,
          //   "offline_loyalty_points"
          // );

          let notSyncedofflineLoyaltyPoints =
            (await GET_OBJECT_STORE(
              "not_synced_offline_loyalty_points",
              "offline_loyalty_points"
            )) || [];

          this.selectedProducts.forEach((product) => {
            notSyncedofflineLoyaltyPoints.push({
              customer: this.sale.customer._id,
              quantity: product.quantity,
              price:
                product.price !== product.initial_price
                  ? product.initial_price
                  : product.price,
              product_point: product.loyalty_points,
              txnID: this.makeid(24),
              op: "earn",
              offline: true,
            });
          });

          await SAVE_OBJECT_STORE(
            "not_synced_offline_loyalty_points",
            "offline_loyalty_points",
            notSyncedofflineLoyaltyPoints
          );

          // Send loyalty points
          // const addCustomerLoyaltyPointsRes = await this.addCustomerLoyaltyPoints();
          // const addCustomerLoyaltyPointsData = await addCustomerLoyaltyPointsRes.json();

          // Data manipulation for DOM and printing
          this.customerLoyaltyData = { Data: {} };
          this.customerLoyaltyData.Data.Points = this.selectedProducts.reduce(
            (total, prod) => {
              return (
                total +
                (prod.price !== prod.initial_price
                  ? prod.initial_price
                  : prod.price) *
                  prod.quantity *
                  prod.loyalty_points *
                  0.01
              );
            },
            0
          );

          // Update data in the indexedDB
          // const offlineLoyaltyPointsDB = await CONNECT_TO_DB(
          //   "offline_db_all_loyalty_points",
          //   1,
          //   "all_loyalty_points"
          // );
          let offlineLoyaltyPoints = await GET_OBJECT_STORE(
            "offline_db_all_loyalty_points",
            "all_loyalty_points"
          );

          if (offlineLoyaltyPoints) {
            (offlineLoyaltyPoints.Data || []).forEach((lp, i) => {
              if (lp.CustomerID === this.sale.customer._id) {
                lp.Points += this.customerLoyaltyData.Data.Points;
                updateFlag = true;
                this.initial_customer_loyalty_points = lp.Points;
              }

              if (
                !updateFlag &&
                offlineLoyaltyPoints.Data.length - 1 === i &&
                this.sale.customer._id
              ) {
                offlineLoyaltyPoints.Data.push({
                  BusinessID: GET_USER_BUSINESS_ID(),
                  Points: this.customerLoyaltyData.Data.Points,
                  CustomerID: this.sale.customer._id,
                });

                this.initial_customer_loyalty_points =
                  this.customerLoyaltyData.Data.Points;
              }
            });
          }

          await SAVE_OBJECT_STORE(
            "offline_db_all_loyalty_points",
            "all_loyalty_points",
            offlineLoyaltyPoints
          );
        }

        // Register Sync event in SW
        navigator.serviceWorker.ready.then((swReg) => {
          swReg.sync.register("not_synced_offline_loyalty_points");
        });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }

      this.isLoading = false;
      // if (!this.sale.customer._id) {
      //   this.isLoading = false;
      // }
    },
    makeid(l) {
      let text = "";
      let charList = "abcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < l; i++) {
        text += charList.charAt(Math.floor(Math.random() * charList.length));
      }

      return text;
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (res.status) {
        const customersData = res.data ? res.data.items || [] : [];

        this.customers = customersData.map((customer) => {
          return {
            ...customer,
            customerFullName:
              (customer.first_name || "") + " " + (customer.last_name || ""),
          };
        });

        // this.filteredCustomers = this.customers;
        this.filteredCustomers = this.customers.slice(0, 30);
      }
    },
    // Here
    addCustomerLoyaltyPoints() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");

      return fetch(
        `https://tractionloyalty-stage.herokuapp.com/customer_loyalty/${GET_USER_BUSINESS_ID()}/${
          this.sale.customer._id
        }`,
        {
          method: "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify(
            this.selectedProducts.map((item) => {
              // (item);
              return {
                quantity: item.quantity || item.count,
                price:
                  item.price !== item.initial_price
                    ? item.initial_price
                    : item.price,
                product_point: item.loyalty_points,
              };
            })
          ),
        }
      );
    },
    setupPayload() {
      return {
        _id: this.sale._id,
        business_id: GET_USER_BUSINESS_ID(),
        loyalty: this.sale.loyaltyData,
        store_credit: this.sale.storeCreditData,
        // discount_type: this.sale.totalDiscount.discount_type,
        discount: this.totalPlaceholder - this.total,
        description: "",
        payment_method: this.selectedOption
          ? this.selectedOption.method
          : "cash",
        sale_agent: GET_USER_DATA().name,
        store_name: this.sale.store.name,
        store_id: this.sale.store._id,
        total_price: this.total,
        loyalty_amount_tendered: this.sale.loyaltyData.active
          ? +this.sale.loyaltyData.redeemValue
          : 0,
        amount_tendered: this.total === 0 ? 0 : this.amountPaid,
        balance: this.calcBalance,
        register_id: "",
        session_id: "",
        customer_id: this.sale.customer._id,
        customer_name: this.saleCustomer || "",
        customer_email: this.sale.customer
          ? this.sale.customer.email || ""
          : "",
        items: this.selectedProducts.map((item) => {
          return {
            _id: item._id,
            name: item.title,
            quantity: item.quantity || item.count,
            price: item.quickSale
              ? item.quickSaleTotal
              : item.price * item.quantity,
            discount: item.quickSale ? 0 : item.initial_price - item.price,
            unit_price: item.quickSale ? item.quickSaleTotal : item.price,
          };
        }),
      };
    },
  },
};
</script>

<style scoped>
.print-slip {
  display: none;
}
@media print {
  .print-slip {
    margin: 0;
    padding: 0;
    display: block;
  }
  .non-print-slip {
    display: none;
  }
}
</style>
